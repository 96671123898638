import styled from "styled-components";
import { avatarData, textData } from "../sponsorsData.js";

function AvatarGroup(props) {
  const { num } = props;
  return (
    <AvatarGroupCss className="title-avatars">
      {avatarData.map((t, i) => {
        if (i <= num) {
          return (
            <div key={i} className="popup-avatar-wrap">
              <img src={t.img} alt="avatar" />
            </div>
          );
        }
      })}
      <span>+{avatarData.length + textData.length}</span>
    </AvatarGroupCss>
  );
}

const AvatarGroupCss = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;

  .popup-avatar-wrap {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-right: 6px;
  }

  img {
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: 100%;
    transition: var(--ease-1);

    &:hover {
      transform: translateY(-16%);
    }
  }

  span {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 0.01em;
    color: #e8e8e8;
    opacity: 0.8;
  }
`;

export default AvatarGroup;
