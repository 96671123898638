const aliyun =
  "https://yancymin.oss-cn-beijing.aliyuncs.com/love.figma.cool/avatars/";

export const avatarData = [
  {
    name: "piiitt",
    img: `${aliyun}piiitt.jpg`,
  },
  {
    name: "emmm",
    img: `${aliyun}emmm.jpg`,
  },
  {
    name: "DK.文彬",
    img: `${aliyun}DK.文彬.jpg`,
  },
  {
    name: "Chengoh",
    img: `${aliyun}Chengoh.jpg`,
  },
  {
    name: "知了青年",
    img: `${aliyun}知了青年.jpg`,
  },
  {
    name: "饮水机",
    img: `${aliyun}饮水机.jpg`,
  },
  {
    name: "斜杠7湿兄",
    img: `${aliyun}斜杠7湿兄.jpg`,
  },
  {
    name: "带带大师兄",
    img: `${aliyun}带带大师兄.jpg`,
  },
  {
    name: "Sean",
    img: `${aliyun}Sean.jpg`,
  },
  {
    name: "_RennnN",
    img: `${aliyun}_RennnN.jpg`,
  },
  {
    name: "嘉利顿",
    img: `${aliyun}嘉利顿.jpg`,
  },
  {
    name: "KevinShiCN",
    img: `${aliyun}KevinShiCN.jpg`,
  },
  {
    name: "暴力丸子～",
    img: `${aliyun}暴力丸子～.jpg`,
  },
  {
    name: "秘密虾条",
    img: `${aliyun}秘密虾条.jpg`,
  },
  {
    name: "孔助振",
    img: `${aliyun}孔助振.jpg`,
  },
  {
    name: "Jessie",
    img: `${aliyun}Jessie.jpg`,
  },
  {
    name: "Killorange",
    img: `${aliyun}Killorange.jpg`,
  },
  {
    name: "小龙的随心所欲",
    img: `${aliyun}小龙的随心所欲.jpg`,
  },
  {
    name: "石开",
    img: `${aliyun}石开.jpg`,
  },
  {
    name: "ZJH",
    img: `${aliyun}ZJH.jpg`,
  },
  {
    name: "喵局长",
    img: `${aliyun}喵局长.jpg`,
  },
  {
    name: "Listen.",
    img: `${aliyun}Listen..jpg`,
  },
  {
    name: "Jun",
    img: `${aliyun}Jun.jpg`,
  },
  {
    name: "山海",
    img: `${aliyun}山海.jpg`,
  },
  {
    name: "Shuhari",
    img: `${aliyun}Shuhari.jpg`,
  },
  {
    name: "Clu",
    img: `${aliyun}Clu.jpg`,
  },
  {
    name: "Alban-白",
    img: `${aliyun}Alban-白.jpg`,
  },
  {
    name: "小专羊",
    img: `${aliyun}小专羊.jpg`,
  },
  {
    name: "Figmaist",
    img: `${aliyun}Figmaist.jpg`,
  },
  {
    name: "Gwo-",
    img: `${aliyun}Gwo-.jpg`,
  },
  {
    name: "Laborer",
    img: `${aliyun}Laborer.jpg`,
  },
  {
    name: "Loooke19",
    img: `${aliyun}Loooke19.jpg`,
  },
  {
    name: "tz",
    img: `${aliyun}tz.jpg`,
  },
  {
    name: "MouT.me",
    img: `${aliyun}MouT.me.jpg`,
  },
  {
    name: "LZH²⁰²¹",
    img: `${aliyun}LZH²⁰²¹.jpg`,
  },
  {
    name: "LorenzoX",
    img: `${aliyun}LorenzoX.jpg`,
  },
  {
    name: "Frad",
    img: `${aliyun}Frad.jpg`,
  },
  {
    name: "fenx",
    img: `${aliyun}fenx.jpg`,
  },
  {
    name: "dee",
    img: `${aliyun}dee.jpg`,
  },
  {
    name: "caocanx",
    img: `${aliyun}caocanx.jpg`,
  },
  {
    name: "诺墨",
    img: `${aliyun}诺墨.jpg`,
  },
  {
    name: "SHAY",
    img: `${aliyun}SHAY.jpg`,
  },
  {
    name: "Raychen",
    img: `${aliyun}Raychen.jpg`,
  },
  {
    name: "KongSamuel",
    img: `${aliyun}KongSamuel.jpg`,
  },
  {
    name: "章三疯",
    img: `${aliyun}章三疯.jpg`,
  },
  {
    name: "撸铁娃",
    img: `${aliyun}撸铁娃.jpg`,
  },
  {
    name: "阿小小海",
    img: `${aliyun}阿小小海.jpg`,
  },
  {
    name: "YueYueGuy",
    img: `${aliyun}YueYueGuy.jpg`,
  },
  {
    name: "曹蔚",
    img: `${aliyun}曹蔚.jpg`,
  },
  {
    name: "Perry",
    img: `${aliyun}Perry.jpg`,
  },
  {
    name: "努力的五花肉",
    img: `${aliyun}努力的五花肉.jpg`,
  },
  {
    name: "吉祥物",
    img: `${aliyun}吉祥物.jpg`,
  },
  {
    name: "Shingo",
    img: `${aliyun}Shingo.jpg`,
  },
  {
    name: "Zane",
    img: `${aliyun}Zane.jpg`,
  },
  {
    name: "Popouloss",
    img: `${aliyun}Popouloss.jpg`,
  },
  {
    name: "Jamin Liu",
    img: `${aliyun}Jamin%20Liu.jpg`,
  },
  {
    name: "chi",
    img: `${aliyun}chi.jpg`,
  },
  {
    name: "bloodyxu",
    img: `${aliyun}bloodyxu.jpg`,
  },
  {
    name: "AY",
    img: `${aliyun}AY.jpg`,
  },
  {
    name: "吉瑞",
    img: `${aliyun}吉瑞.jpg`,
  },
  {
    name: "op7418",
    img: `${aliyun}op7418.jpg`,
  },
  {
    name: "zanwei.guo",
    img: `${aliyun}zanwei.guo.jpg`,
  },
  {
    name: "Siing",
    img: `${aliyun}Siing.jpg`,
  },
  {
    name: "Pel",
    img: `${aliyun}Pel.jpg`,
  },
  {
    name: "Charley",
    img: `${aliyun}Charley.jpg`,
  },
  {
    name: "JJ Ying",
    img: `${aliyun}JJ%20Ying.jpg`,
  },
  {
    name: "最美赞助者",
    img: `${aliyun}最美赞助者.jpg`,
  },
  {
    name: "粢饭大人",
    img: `${aliyun}粢饭大人.jpg`,
  },
  {
    name: "钟二信",
    img: `${aliyun}钟二信.jpg`,
  },
  {
    name: "我就是贺",
    img: `${aliyun}我就是贺.jpg`,
  },
  {
    name: "梦洁洁",
    img: `${aliyun}梦洁洁.jpg`,
  },
  {
    name: "陈旭",
    img: `${aliyun}陈旭.jpg`,
  },
  {
    name: "ZK",
    img: `${aliyun}ZK.jpg`,
  },
  {
    name: "PJY",
    img: `${aliyun}PJY.jpg`,
  },
  {
    name: "李普",
    img: `${aliyun}李普.jpg`,
  },
  {
    name: "jinglin",
    img: `${aliyun}jinglin.jpeg`,
  }
];

export const textData = [
  {
    name: "Dreity",
  },
  {
    name: "韩梅梅",
  },
  {
    name: "小杰",
  },
  {
    name: "Worthwhile",
  },
  {
    name: "xiaopipi",
  },
  {
    name: "科扬",
  },
  {
    name: "腾讯CoDesign",
  },
  {
    name: "北海扶喵",
  },
  {
    name: "墨飞",
  },
  {
    name: "chauncey",
  },
  {
    name: "花花花乱开",
  },
  {
    name: "Hood",
  },
  {
    name: "在在",
  },
  {
    name: "limin",
  },
  {
    name: "岁岁",
  },
  {
    name: "李三生",
  },
  {
    name: "炳琰",
  },
  {
    name: "道骨仙风",
  },
  {
    name: "阿墩",
  },
  {
    name: "CONNIE",
  },
  {
    name: "为万世开太平",
  },
  {
    name: "hanbaba",
  },
  {
    name: "开飞机的贝塔",
  },
  {
    name: "Archieye",
  },
  {
    name: "缒缘",
  },
  {
    name: "科扬",
  },
  {
    name: "鱼翅",
  },
  {
    name: "李岳龙",
  },
  {
    name: "你的益达",
  },
  {
    name: "宋语轩",
  },
  {
    name: "wanxu001",
  },
  {
    name: "Suho Huang",
  },
  {
    name: "张啊田啊鱼啊",
  },
  {
    name: "元明",
  },
  {
    name: "刘肉丸",
  },
  {
    name: "halo",
  },
  {
    name: "地球啊",
  },
  {
    name: "aikaaa",
  },
  {
    name: "andy",
  },
  {
    name: "JJX",
  },
  {
    name: "wangan王安",
  },
  {
    name: "霖二",
  },
  {
    name: "smdmy",
  },
  {
    name: "周鑫珂",
  },
  {
    name: "盖世",
  },
  {
    name: "岳傍晚",
  },
  {
    name: "mengyang",
  },
  {
    name: "azuaqu",
  },
  {
    name: "Sophie",
  },
  {
    name: "WJ 浅笺素语",
  },
  {
    name: "xwongger",
  },
  {
    name: "zac",
  },
  {
    name: "越越小",
  },
  {
    name: "大鹏_abo",
  },
  {
    name: "時·光",
  },
  {
    name: "無我",
  },
  {
    name: "beebee",
  },
  {
    name: "钟钟钟",
  },
  {
    name: "Lucas7",
  },
  {
    name: "maoqin",
  },
  {
    name: "zklm0000",
  },
  {
    name: "王业凯",
  },
  {
    name: "Dora",
  },
  {
    name: "明日故乡",
  },
  {
    name: "Puffcake",
  },
  {
    name: "arthorn",
  },
  {
    name: "妞青",
  },
  {
    name: "Supersmart",
  },
  {
    name: "pro🌟",
  },
  {
    name: "1吟",
  },
  {
    name: "王DUOMI",
  },
  {
    name: "kooloool",
  },
  {
    name: "SEBASTIAN",
  },
  {
    name: "😝",
  },
  {
    name: "39°",
  },
  {
    name: "Yair",
  },
  {
    name: "Somnus",
  },
  {
    name: "不加糖的kk",
  }
];
