import styled from "styled-components";
// import TabSwitcher from "../components/TabSwitcher";
import Title from "../components/Title";
import AvatarGroup from "../components/AvatarGroup";
// import { avatarData, textData } from "../sponsorsData.js";
import qrWechat from "../assets/qr-wechat.png";
import qrAlipay from "../assets/qr-alipay.png";
import iconArrow from "../assets/summary-arrow.svg";

function Popup(props) {
  const { popupState, closeFn } = props;
  return (
    <PopupCss className={popupState ? "popup-show" : ""}>
      <div className="popup-wrap">
        <div className="popup-content-top">
          <div
            className="popup-close"
            onClick={() => {
              closeFn(false);
            }}
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.26113 18.9674C3.91802 19.3105 3.90793 19.8958 4.26113 20.2389C4.61433 20.582 5.19963 20.582 5.54274 20.2389L12.0013 13.7804L18.4598 20.2389C18.8029 20.582 19.3983 20.5921 19.7414 20.2389C20.0845 19.8857 20.0845 19.3105 19.7414 18.9674L13.2829 12.4987L19.7414 6.04022C20.0845 5.69711 20.0946 5.11181 19.7414 4.7687C19.3882 4.4155 18.8029 4.4155 18.4598 4.7687L12.0013 11.2272L5.54274 4.7687C5.19963 4.4155 4.60424 4.40541 4.26113 4.7687C3.91802 5.1219 3.91802 5.69711 4.26113 6.04022L10.7196 12.4987L4.26113 18.9674Z"
                fill="#EBEBF5"
                fillOpacity="0.6"
              />
            </svg>
          </div>
          <Title title="感谢来自你的赞助！" />
          <p className="title-des">
            通过支持 Figma.Cool，你将成为我们创作过程中的积极参与者。
          </p>
          <AvatarGroup num={6} />
        </div>
        <main className="popup-content">
          <article>
            <h3>赞助流程</h3>
            <ul>
              <li>从右侧选择支付平台，扫描二维码完成赞助；</li>
              <li>点击「完成赞助」按钮，填写赞助者信息。</li>
            </ul>
            <h3>我们的回馈</h3>
            <ul className="popup-return-list">
              <li>在 Love.Figma.Cool 页面留下你的名字和头像；</li>
              <li>加入 Figma.Cool 赞助者微信群，结交新的朋友；</li>
              <li>享受后续 Figma.Cool 针对赞助者的所有回报。</li>
            </ul>
            <h3>常见问题</h3>
            <div className="q-a">
              <details>
                <summary>赞助者列表是实时更新的吗？</summary>
                <p>因为是人工操作，我们会在每周周三、六进行网站更新。</p>
              </details>
              <details>
                <summary>点击「完成赞助」后填的信息之后能修改吗？</summary>
                <p>抱歉，不能修改。也就是说只有一次机会，请仔细填写表单哦。</p>
              </details>
              <details>
                <summary>我可以随意填写赞助者信息吗？</summary>
                <p>
                  当然啦。但是不能填写敏感词等违规违法信息，头像也包括在内，感谢理解。
                </p>
              </details>
              <details> 
                <summary>Plus 赞助者和赞助者这两者有何区别？</summary>
                <p>
                  赞助我们超过 50 元人民币成为 Plus
                  赞助者，可以在这个页面中留下名字和头像，1-50
                  元人民币的赞助者可以留下你的名字。无论赞助多少，我们都真诚地感谢大家的支持。
                </p>
              </details>
            </div>
          </article>
          <section className="pay-panel">
            <div className="qr-wrap">
              <div className="qr-wechat">
                <img src={qrWechat} alt="qrWechat" />
                <p>微信 WeChat Pay</p>
              </div>
              <div className="qr-alipay">
                <img src={qrAlipay} alt="alipay" />
                <p>支付宝 Alipay</p>
              </div>
            </div>
            <div className="pay-select">
              <div className="sponsor plus">
                <div>
                  <h3>Plus 赞助者</h3>
                  <p className="des">赞助超过 50 元人民币</p>
                </div>
                <a
                  href="https://wj.qq.com/s2/8711603/48e8"
                  className="paied-button"
                  target="_blank"
                >
                  完成赞助
                </a>
              </div>
              <i></i>
              <div className="sponsor">
                <div>
                  <h3>赞助者</h3>
                  <p className="des">赞助 1 - 50 元人民币</p>
                </div>
                <a
                  href="https://wj.qq.com/s2/8711635/fe8e"
                  className="paied-button"
                  target="_blank"
                >
                  完成赞助
                </a>
              </div>
            </div>
            <span>*赞助后选择不填写赞助人信息将会成为隐身赞助者</span>
          </section>
        </main>
      </div>
      <span className="popup-mask"></span>
    </PopupCss>
  );
}

const PopupCss = styled.section`
  visibility: hidden;
  opacity: 0;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999999999;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--ease-3);
  overflow: hidden;

  .popup-wrap {
    z-index: 999;
  }

  .popup-content-top,
  .popup-content {
    transform: translate3d(0, -200px, 0);
    opacity: 0;
    transition: var(--ease-4);
  }

  .popup-content-top {
    z-index: 999;
    /* position: fixed; */
    top: 40px;
    max-width: 900px;
    width: 100%;
    padding: 48px;
    border-radius: 32px 32px 0 0;
    border-bottom: 1px solid var(--cr-line-1);
    background: var(--cr-bg-2);
    /* backdrop-filter: blur(120px); */

    .popup-close {
      position: absolute;
      top: 24px;
      right: 24px;
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--cr-bg-1);
      border-radius: 16px;
      cursor: pointer;
      transition: var(--ease-1);
      transform: scale(1);

      &:hover {
        opacity: 0.8;
        transform: scale(0.98);
      }

      &:active {
        transform: scale(0.9);
      }
    }

    h1 {
      margin-bottom: 12px;
    }
    p {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.01em;
      color: #e8e8e8;
      opacity: 0.8;
    }

    .title-avatars {
      margin-top: 16px;
      display: flex;
      align-items: center;

      .popup-avatar-wrap {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        margin-right: 6px;
      }

      img {
        width: 24px;
        height: 24px;
        object-fit: cover;
        border-radius: 100%;
        transition: var(--ease-1);

        &:hover {
          transform: translateY(-16%);
        }
      }

      span {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 13px;
        letter-spacing: 0.01em;
        color: #e8e8e8;
        opacity: 0.8;
      }
    }
  }

  .pay-panel {
    position: sticky;
    top: 0;
    right: 0;
    width: 355px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;

    .qr-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 216px;
      background: #292929;
      border-radius: 32px 32px 0 0;

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .qr-wechat {
        margin-right: 20px;
      }

      img {
        width: 140px;
        height: 140px;
      }
      p {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        color: rgba(235, 235, 245, 0.6);
        margin-top: 12px;
      }
    }

    span {
      position: absolute;
      bottom: -24px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.01em;
      color: rgba(255, 255, 255, 0.9);
      opacity: 0.4;
    }

    .pay-select {
      width: 100%;
      padding: 24px;
      display: flex;
      flex-direction: column;
      background: var(--cr-bg-1);
      overflow: hidden;
      border-radius: 0 0 32px 32px;

      i {
        width: 100%;
        height: 1px;
        background: #181818;
        margin: 18px 0;
      }
      .sponsor {
        position: relative;
        display: flex;
        justify-content: space-between;

        &:last-child {
          &:after {
            top: -18px;
          }
        }

        &:hover {
          &:after {
            opacity: 1;
          }

          p {
            opacity: 1;
          }
        }

        &:after {
          z-index: 0;
          position: absolute;
          content: "";
          width: calc(100% + 48px);
          height: calc(100% + 42px);
          background: rgb(255 255 255 / 6%);
          top: -24px;
          left: -24px;
          opacity: 0;
          transition: var(--ease-1);
        }

        & > div {
          display: flex;
          flex-direction: column;
        }

        h3 {
          display: inline;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
            Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 28px;
          letter-spacing: 0.35px;
          color: #ffffff;
          margin-bottom: 4px;
        }

        p {
          display: inline;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
            Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          line-height: 16px;
          color: #e8e8e8;
          opacity: 0.4;
          transition: var(--ease-1);
        }

        a {
          z-index: 1;
          position: relative;
          /* top: 0;
          right: 0; */
          width: fit-content;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
            Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 17px;
          line-height: 22px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: -0.408px;
          color: #ffffff;
          padding: 12px 16px;
          border-radius: 60px;
          background: var(--color-iceBlue-1);
          transition: var(--ease-1);

          &::after {
            z-index: -1;
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            width: 100%;
            height: 100%;
            border-radius: 60px;
            background: var(--color-iceBlue-1);
            transition: var(--ease-1);
          }

          &:hover {
            &::after {
              transform: scale(1.08);
            }
          }
        }
      }
    }
  }

  .popup-content::-webkit-scrollbar {
    display: none;
  }

  .popup-content {
    z-index: 10;
    /* position: absolute; */
    top: 277px;
    display: flex;
    justify-content: space-between;
    max-width: 900px;
    width: 100%;
    padding: 48px;
    background: var(--cr-bg-2);
    border-radius: 0 0 32px 32px;
    overflow-y: scroll;

    article {
      max-width: 50%;
      h3 {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 26px;
        color: rgba(255, 255, 255, 0.9);
        margin-bottom: 20px;
      }

      .popup-return-list {
        li {
          list-style-type: disc;
          margin-left: 20px;
        }
      }

      ul {
        margin-bottom: 56px;
        li {
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
            Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.01em;
          color: #e8e8e8;
          opacity: 0.8;
          list-style-type: decimal;
          margin-left: 18px;
        }
      }

      .q-a {
        summary {
          position: relative;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
            Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.01em;
          color: #e8e8e8;
          padding-left: 16px;
          cursor: pointer;
          transition: var(--ease-1);
          margin: 4px 0;

          &:hover {
            color: white;
          }
        }

        summary::-webkit-details-marker {
          display: none;
          content: "";
        }
        summary::marker {
          display: none;
          content: "";
        }

        summary::after {
          content: "";
          width: 8px;
          height: 10px;
          background: url(${iconArrow}) no-repeat;
          background-size: cover;
          display: block;
          position: absolute;
          top: 7px;
          left: 0;
          transition: var(--ease-1);
        }

        details[open] summary::after {
          transform: rotate(90deg);
        }
        details[open] summary {
          color: white;
          font-weight: 500;
        }

        details {
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
            Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.01em;
          color: #e8e8e8;
          opacity: 0.8;
          margin-bottom: 4px;

          p {
            margin-bottom: 16px;
            font-weight: 300;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
              Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
              sans-serif;
            font-style: normal;
            font-size: 14px;
            line-height: auto;
          }
        }
      }
    }
  }

  .popup-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgb(255 255 255 / 4%);
    transition: var(--ease-3);
    backdrop-filter: blur(120px) saturate(180%);
  }
`;

export default Popup;
