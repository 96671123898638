import { createGlobalStyle } from "styled-components";

const MobileStyles = createGlobalStyle`


@media screen and (max-width: 900px) {

    body {
        margin-bottom: 380px !important;
    }

    p {
        max-width: unset !important;
    }

    .page-top-left {
        display: none !important;
    }

    .page-top-right {
        width: 100% !important;
    }

    .hero-btn, #dock, #returns-card a {
        display: none !important;
    }

    #primary-card .card-content li{
        font-size: 24px;
        line-height: 24px;

      .dot {
           height: 16px !important;
       }
    }

    .slogan-motion-obj {
        width: 32px !important;
        height: 32px !important;

        img {
            width: 16px !important;
            height: 16px !important;
        }
    }

    .primary-card-avatars {
        height: 32px !important;
        width: 78px !important;

        .slogan-motion-obj {
        &:nth-of-type(2) {
          left: 22px !important;
        }

        &:nth-of-type(3) {
          left: 44px !important;
        }
        }
    }

    #article-card .card-cover {
        height: 460px !important;
    }

    .article-card-avatar-wrap {
        &:nth-of-type(1),   &:nth-of-type(2),  &:nth-of-type(4),  &:nth-of-type(6),  &:nth-of-type(8) {
            display: none !important;
        }

    }

    .p-text {
        font-size: 14px !important;
        line-height: 25px !important;
    }

    h1 {
        font-size: 32px !important;
        line-height: 40px !important;
    }

    .subTitle {
        font-size: 14px !important;
        line-height: 14px !important;
    }

    .sponsors-card-top {
        display: flex;
        flex-direction: column;

        .popup-avatar-wrap {
            &:nth-of-type(1),   &:nth-of-type(2),  &:nth-of-type(3) , &:nth-of-type(4),  &:nth-of-type(5) {
             display: none !important;
            }
        }

        .input-wrap {
            width: 100% !important;
            position: unset !important;
            margin-top: 24px;
        }
    }

    .sponsors-wrap .avatar-wrap {
            grid-template-columns: repeat(4,minmax(65px,100%)) !important;
        }

        .sponsors-card-item-text, .sponsors-card-item span {
            font-size: 14px !important;
            line-height: 14px !important; 
        }

        .sponsors-card-item span {
            bottom: 6px !important;
            left: 6px !important;
        }

        footer {
            padding: 60px !important;

            .footer-logo {
                width: 200px !important;
                height: 200px !important;
            }
        }

        #article-card .card-content, #project-card, #returns-card .card-content, .sponsors-card-top{
            padding: 32px !important;;
        }

        section {
            transform: translateZ(0);
        }

        .tabs {
            width: 80% !important; 
        }

        .statistic {
            display: grid !important; 
            grid-template-columns: repeat(2,minmax(65px,100%)) !important; 
            grid-gap: 20px;
            margin: 32px 0 !important;

        h6 {
            font-size: 14px !important;
            line-height: 14px !important;
            margin-bottom: 8px !important;
        }

            span {
                font-size: 26px !important;
                line-height: 26px !important;
            }
         }

         #project-card {
             height: 930px !important;  

             h1 {
                 width: 200px !important;
             }

             .title-left:after {
             display: none !important; 
            }
         }
}

`;

export default MobileStyles;
