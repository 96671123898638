import styled from "styled-components";
import Title from "../components/Title";
import Spline3d from "./Spline3d";

function ReturnsCard(props) {
  const { btnClick } = props;
  return (
    <ReturnsCardCss id="returns-card" className="top-card">
      <Spline3d />
      <div className="card-content">
        <Title
          title="我们的回馈"
          btn="赞助我们"
          subTitle="感谢幕后支持"
          btnClick={btnClick}
        />
        <p className="p-text">
          通过支持
          Figma.Cool，你将成为我们创作过程中的积极参与者。作为赞助者，你将获得独家内容，幕后更新以及认识一群志同道合的朋友们。
        </p>
        <ul className="p-text">
          <li>在 Love.Figma.Cool 的页面中留下你的名字和头像（自愿）；</li>
          <li>加入 Figma.Cool 赞助共建者微信群，结交志同道合的朋友；</li>
          <li>享受后续 Figma.Cool 针对赞助者的所有回报。</li>
        </ul>
      </div>
    </ReturnsCardCss>
  );
}

const ReturnsCardCss = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: calc(60% - 20px); */
  width: 100%;
  height: 760px;
  background: var(--cr-bg-2);
  /* margin-left: 20px; */
  border-radius: 32px;
  overflow: hidden;

  .card-title {
    align-items: center;
  }

  .spline-3d div {
    display: flex;
    justify-content: center;
  }

  .spline-3d canvas {
    position: relative;
    top: -140px;
    cursor: grab;
    width: 120% !important;
    height: unset !important;
    filter: saturate(0) invert(1) brightness(1.8);
    outline: none;
  }

  .card-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 48px;
    background: rgb(23 23 23 / 0%);
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);

    p {
      max-width: 70%;
    }

    .card-title {
      margin-bottom: 12px;
    }

    ul {
      margin-top: 24px;
      margin-left: 24px;

      li {
        list-style-type: disc;
      }
    }
  }
`;

export default ReturnsCard;
