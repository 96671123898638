import { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import GlobalStyles from "../src/style/global";
import MobileStyles from "../src/style/mobile";
import LogoComp from "../src/components/LogoComp";
import PrimaryCard from "../src/components/PrimaryCard";
import ArticleCard from "../src/components/ArticleCard";
import ProjectCard from "../src/components/ProjectCard";
import ReturnsCard from "../src/components/ReturnsCard";
import SponsorsCard from "./components/SponsorsCard";
import GlobalFooter from "../src/components/GlobalFooter";
import Dock from "../src/components/Dock";
import styled from "styled-components";
import { gsap } from "gsap/all";
import { SplitText } from "./SplitText";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Popup from "../src/components/Popup";

gsap.registerPlugin(ScrollTrigger);

function App() {
  const [projectMotion, setProjectMotion] = useState("");
  const pageTopDom = useRef(null);
  let widthDefaultNum = 0;
  let leftDistance = 0;
  let logoCompHeight = 0;
  let scrollEndHeight = 0;

  useEffect(() => {
    if (
      /Safari/.test(navigator.userAgent) &&
      !/Chrome/.test(navigator.userAgent)
    ) {
      const sections = document.querySelectorAll(".top-card");
      sections.forEach((t) => {
        t.classList.add("safari-section");
      });
    }
  }, []);

  useEffect(() => {
    function widthDefault() {
      const primaryCardDom = document.querySelector("#primary-card");
      const pageTopRightDom = document.querySelector(".page-top-right");
      const logoCompDom = document.querySelector(".logo-comp");
      scrollEndHeight = pageTopDom.current.offsetHeight;
      logoCompHeight = logoCompDom.offsetHeight;
      if (primaryCardDom) {
        primaryCardDom.style.width = `${pageTopRightDom.offsetWidth}px`;
        widthDefaultNum = pageTopRightDom.offsetWidth;
      }
    }

    leftDistance = document
      .querySelector(".page-top-right")
      .getBoundingClientRect().left;

    widthDefault();

    window.addEventListener("resize", () => {
      widthDefault();
      leftDistance = document
        .querySelector(".page-top-right")
        .getBoundingClientRect().left;
    });
  }, []);

  useEffect(() => {
    const tl = gsap.timeline();

    gsap.set(window, { scrollTo: { y: 0, x: 0 } });

    // ----------------------------------- logo scroll fixed

    gsap.set(".logo-comp", {
      position: "relative",
      opacity: 1,
    });

    const logoCompTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".logo-comp",
        start: "-20px",
        // endTrigger: ".sponsors-card-top",
        end: `${scrollEndHeight}px 468px`,
        toggleActions: "play none none reverse",
        pin: true,
        pinType: "fixed",
        id: "logo-comp",
        markers: false,
        pinReparent: false,
        pinSpacing: false,
        scrub: true,
        onUpdate: (self) => {
          gsap.set(".textCircle", {
            force3D: true,
            rotate: self.progress.toFixed(3) * 200,
          });
        },
      },
    });

    // logoCompTl.to(".logo-comp", {
    //   scrollTrigger: {
    //     trigger: ".logo-comp",
    //     endTrigger: ".page-top",
    //     toggleActions: "play none none reverse",
    //     start: "-20px",
    //     end: " bottom bottom",
    //     pin: true,
    //     pinType: "fixed",
    //     id: "logo-comp",
    //     markers: true,
    //     pinReparent: true,
    //     pinSpacing: false,
    //     // scrub: 1,
    //   },
    // });

    // ------------------------------------  hero slogan text motion

    const parentSplit = new SplitText("#primary-card .card-content p", {
      type: "lines",
      linesClass: "split-parent",
    });
    const childSplit = new SplitText("#primary-card .card-content p", {
      type: "chars",
    });

    gsap.from(childSplit.chars, {
      duration: 0.5,
      ease: "cubic-bezier(0.16, 1, 0.3, 1)",
      yPercent: 100,
      stagger: 0.018,
      delay: 1.1,
      onComplete: () => {
        parentSplit.revert();
        childSplit.revert();
      },
    });

    // ------------------------------------  hero motion

    gsap.set("#primary-card", {
      ease: "ease",
      width: "100vw",
      height: "100vh",
      borderRadius: 0,
      zIndex: 1,
      left: -leftDistance,
      top: -20,
      right: 0,
      x: 0,
      y: 0,
    });

    gsap.set(".slogan-motion-obj", {
      opacity: 0,
    });
    gsap.set(".slogan-motion-logo", {
      opacity: 0,
      rotate: 180,
      scale: 2,
    });
    gsap.set(".slogan-motion-add", {
      opacity: 0,
      rotate: 180,
      scale: 2,
    });
    gsap.set(".slogan-motion-avatar div", {
      opacity: 0,
      rotate: 180,
      scale: 2,
      x: 100,
    });
    gsap.set(".hero-btn", {
      opacity: 0,
      y: -16,
    });
    gsap.set("#primary-card canvas", {
      opacity: 0,
      scale: 8,
    });
    gsap.set("#primary-card .card-content", {
      opacity: 0,
    });
    gsap.set(".logo-comp", {
      opacity: 0,
    });
    gsap.set(".comp-logo", {
      perspective: 1000,
      rotateY: -90,
    });
    gsap.set("#article-card .card-cover li", {
      opacity: 0,
    });
    gsap.set(".logoWrap span", {
      filter: "blur(40px)",
      opacity: 0,
    });

    tl.to("#primary-card .card-content", {
      ease: "Power4.easeOut",
      opacity: 1,
      duration: 0.6,
    })
      .to(
        ".slogan-motion-logo",
        {
          opacity: 0.8,
          rotate: 0,
          duration: 0.4,
          scale: 1,
          delay: 0.5,
        }
        // "+=0.3"
      )
      .to(
        ".slogan-motion-add",
        {
          opacity: 0.8,
          rotate: 0,
          duration: 0.4,
          scale: 1,
        }
        // "+=0.1"
      )
      .to(
        ".slogan-motion-avatar div",
        {
          ease: "cubic-bezier(0.34, 1.56, 0.64, 1)",
          opacity: 1,
          rotate: 0,
          duration: 0.6,
          scale: 1,
          stagger: 0.1,
          x: 0,
        }
        // "+=0.3"
      )
      .to(
        "#primary-card canvas",
        {
          ease: "ease-out",
          opacity: 1,
          scale: 1,
          duration: 1.6,
        },
        "-=1.2"
      )
      .to(
        ".hero-btn",
        {
          ease: "ease",
          opacity: 1,
          y: 0,
          duration: 0.3,
        },
        "-=1"
      )
      .from("#dock", {
        ease: "cubic-bezier(0.16, 1, 0.3, 1)",
        opacity: 0,
        y: 100,
        duration: 0.6,
        // delay: 0.4,
      });

    gsap.to("#primary-card", {
      ease: "cubic-bezier(0.16, 1, 0.3, 1)",
      zIndex: 1,
      duration: 0.8,
      width: widthDefaultNum,
      height: 700,
      left: 0,
      top: 0,
      right: 20,
      x: 0,
      y: 0,
      borderRadius: 32,
      onComplete: () => {
        const heroEndTl = gsap.timeline();
        heroEndTl
          .to(".logo-comp", {
            duration: 0.8,
            opacity: 1,
          })
          .to(".logoWrap .comp-logo", {
            perspective: 1000,
            rotateY: 0,
            duration: 0.4,
          })
          .to(".logoWrap span", {
            filter: "blur(0)",
            duration: 1,
            opacity: 1,
          });

        generate();
      },
      // onEnter: () => {},
      scrollTrigger: {
        trigger: "#primary-card",
        toggleActions: "play none none none",
        start: "top",
        // animation: gsap.to("#primary-card", {}),
        // end: "20%",
        scrub: false,
        // once: true,
        // maker: true,
        // pin: true,
        // pinSpacing: true,
      },
    });

    // --------- avatar motion
    function generate() {
      const articleTl = gsap.timeline();

      function blurRandom() {
        const nums = [0, 0, 0, 0, 16, 10];
        function getRandomInt(max) {
          return Math.floor(Math.random() * max);
        }
        return "blur(" + nums[getRandomInt(7)] + "px)";
      }

      articleTl
        .to("#article-card .card-cover li", {
          opacity: 1,
          ease: "elastic.out(1, 0.5)",
          duration: 4,
          stagger: {
            amount: 2,
          },
          filter: () => blurRandom(),
        })
        .to("#article-card .card-cover li", {
          x: "random(-15, 4, true)",
          y: 0,
          ease: "elastic.out(1, 0.5)",
          duration: 6,
          delay: "random(0, 0.2, true)",
        })
        .to("#article-card .card-cover li", {
          x: "random(-15, 20, true)",
          y: "random(-15, 20, true)",
          ease: "ease",
          scale: () => {
            const nums = [0.9, 1];
            function getRandomInt(max) {
              return Math.floor(Math.random() * max);
            }
            return nums[getRandomInt(2)];
          },
          duration: 4,
          filter: () => blurRandom(),
          repeat: -1,
          repeatRefresh: true,
          // repeatDelay: 0.6,
        });
    }
  }, []);

  const [popupState, setPopupState] = useState(false);

  function tipsHandle(t) {
    setPopupState(t);
    setTimeout(() => {
      setPopupState(false);
    }, 2000);
  }

  return (
    <AppCss>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Title</title>
        <title>Figma.Cool 赞助计划</title>
        <meta
          name="description"
          content="你的赞助对我们来说意义重大，因为有大家的支持才能让我们专注于维护 FIgma.Cool 和构想新的想法，而不必再担心资金的压力。"
        />
        <meta name="theme-color" content="#181818" />
      </Helmet>
      {/* <Popup popupState={popupState} closeFn={(t) => setPopupState(t)} /> */}
      <div className={popupState ? "tips tips-show" : "tips"}>❤️ 谢谢你的关注，已停止赞助啦～</div>
      <Dock btnClick={(t) => tipsHandle(t)} />
      <GlobalStyles />
      <MobileStyles />
      <div className="page-top" ref={pageTopDom}>
        <div className="page-top-left">
          <LogoComp />
        </div>
        <div className="page-top-right">
          <PrimaryCard btnClick={(t) => tipsHandle(t)} />
          <ArticleCard id="article-card" />
          <ProjectCard motion={projectMotion} />
          <ReturnsCard btnClick={(t) => tipsHandle(t)} />
        </div>
      </div>
      <SponsorsCard />
      <GlobalFooter />
    </AppCss>
  );
}

const AppCss = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100vw - 40px);
  max-width: calc(1600px - 40px);
  background: var(--cr-bg-1);

  .tips-show {
    opacity: 1 !important;
    visibility: visible !important;
    transform: translateY(0) !important;
  }

  .tips {
    position: fixed;
    z-index: 9999;
    bottom: 120px;
    opacity: 0;
    visibility: hidden;
    transition: var(--ease-1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 13px;
    width: fit-content;
    height: 32px;
    padding: 0 12px;
    box-shadow: 0 2px 3px rgb(0 0 0 / 58%),
      inset 0 1px 1px hsl(0deg 0% 100% / 14%);
    background: var(--cr-bg-2);
    border-radius: 4px;
    color: #fff;
    transform: translateY(100%);
  }

  .page-top {
    width: 100%;
    display: flex;
    justify-content: center;

    &-left {
      position: relative;
      width: 100%;
      margin-right: 20px;
    }
    &-right {
      width: calc(100% - 20px - 448px);
    }
  }

  /* .page-medium {
    width: 100%;
    display: flex;
    margin-top: 20px; */

  .project-card {
    margin: 20px 0;
    width: 100%;
    height: 840px;
    border-radius: 32px;
    /* overflow: hidden; */
  }
  /* } */

  .page-top-right {
    /* padding: 720px 0 0 0; */

    #primary-card {
      position: relative;
      /* right: 20px; */
      /* top: 20px; */
    }
  }
`;

export default App;
