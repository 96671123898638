import styled from "styled-components";
import MainButton from "../components/MainButton";
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
.btnShow {
    justify-content: space-between;
}
`;

let btnShow;

function Title(props) {
  const { title, subTitle, btn, btnClick } = props;

  return (
    <>
      <GlobalStyles />
      <TitleCss className={btn ? "btnShow card-title" : "card-title"}>
        <div className="title-left">
          {subTitle ? <span className="subTitle">{subTitle}</span> : ""}
          <h1>{title}</h1>
        </div>
        <div className="title-right">
          {btn ? (
            <MainButton text={btn} btnClick={btnClick}/>
          ) : (
            ""
          )}
        </div>
      </TitleCss>
    </>
  );
}

const TitleCss = styled.section`
  height: fit-content;
  display: flex;
  align-items: flex-end;

  .title-left {
    display: flex;
    flex-direction: column;
  }

  .subTitle {
    width: fit-content;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 300;
    padding: 8px 16px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    color: var(--cr-font-1);
    font-size: 16px;
    line-height: 16px;
    opacity: 0.8;
  }

  h1 {
    white-space: pre-wrap;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 54px;
    color: var(--cr-font-1);
    max-width: 567px;
    margin-top: 12px;
  }
`;

export default Title;
