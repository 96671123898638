import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Title from "../components/Title";
import Avatar from "../components/Avatar";
import TabSwitcher from "../components/TabSwitcher";
import AvatarGroup from "../components/AvatarGroup";
import iconSearch from "../assets/icon-search.png";
import { avatarData } from "../sponsorsData.js";
import { textData } from "../sponsorsData.js";

function SponsorsCard() {
  const [isPlus, setIsPlus] = useState(true);
  const [plusData, setPlusData] = useState(avatarData);
  const [renderData, setRenderData] = useState(plusData);
  const [normalData, setNormalData] = useState(textData);

  const searchInput = useRef(null);

  function shuffleArray(array) {
    let i = array.length - 1;
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  useEffect(() => {
    if (!isPlus) {
      setRenderData(normalData);
    } else {
      setRenderData(plusData);
    }
    // console.log(renderData, isPlus);
  }, [isPlus]);

  // const shuffledAvatarData = shuffleArray(avatarData);

  function searchHandle(e) {
    if (isPlus) {
      setRenderData(
        plusData.filter((t) => {
          let text = t.name.toLocaleLowerCase();
          return text.includes(e.target.value.toLocaleLowerCase());
        })
      );
    } else {
      setRenderData(
        normalData.filter((t) => {
          let text = t.name.toLocaleLowerCase();
          return text.includes(e.target.value.toLocaleLowerCase());
        })
      );
    }
  }

  return (
    <SponsorsCardCss className="top-card">
      <div className="sponsors-card-top">
        <Title title="我们的赞助者" />
        <p className="p-text">
          感谢大家对 Figma.Cool 的帮助和认可，你将成为我们创作过程中的积极参与者。
        </p>
        <AvatarGroup num={10} />
        <div className="input-wrap">
          <img src={iconSearch} alt="iconSearch" />
          <input
            type="text"
            placeholder="搜索赞助者"
            ref={searchInput}
            onChange={(e) => {
              searchHandle(e);
            }}
          />
        </div>
      </div>
      <div className="sponsors-wrap">
        <TabSwitcher changePlus={(t) => setIsPlus(t)} />
        <div className="avatar-wrap">
          {renderData.map((item, i) => {
            if (item.img) {
              return (
                <div className="sponsors-card-item" key={i}>
                  <span>{item.name}</span>
                  <Avatar img={item.img} />
                </div>
              );
            } else {
              return (
                <div className="sponsors-card-item-text" key={i}>
                  <span>{item.name}</span>
                </div>
              );
            }
          })}
        </div>
      </div>
    </SponsorsCardCss>
  );
}

const SponsorsCardCss = styled.section`
  width: 100%;
  background: var(--cr-bg-2);
  margin-top: 20px;
  overflow: hidden;
  border-radius: 32px;

  .sponsors-card-top {
    position: relative;
    padding: 48px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);

    .p-text {
      max-width: 60%;
    }
  }

  h1 {
    margin-top: 0;
    margin-bottom: 12px;
  }

  .sponsors-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    .tabs {
      margin: 40px 0 20px 0;
    }
  }

  .avatar-wrap {
    width: 100%;
    padding: 20px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(10, minmax(65px, 100%));

    .sponsors-card-item {
      position: relative;

      &:hover {
        span {
          opacity: 0;
          transform: scale(0.93);
        }
      }

      span {
        opacity: 1;
        z-index: 1;
        position: absolute;
        bottom: 12px;
        left: 12px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: var(--cr-font-1);
        transition: all 0.2s ease-out;
        transform: scale(1);
        text-shadow: 0px 0px 2px rgb(0 0 0 / 60%);
      }
    }
  }

  .sponsors-card-item-text {
    background: var(--cr-bg-1);
    border-radius: 14px;
    padding: 12px 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #e8e8e8;
    word-break: break-word;
  }

  .input-wrap {
    position: absolute;
    right: 48px;
    bottom: 48px;
    display: flex;
    align-items: center;
    padding: 6px 10px;
    width: 240px;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 10px;
    transition: var(--ease-1);
    border: 1px solid transparent;

    &:hover {
      background: rgba(255, 255, 255, 0.06);
      border-color: var(--cr-line-1);
    }

    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }

    input {
      width: 100%;
      outline: none;
      border: none;
      background: transparent;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 17px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #e8e8e8;
    }
  }
`;

export default SponsorsCard;
