import styled from "styled-components";
import { useEffect, useRef, useState } from "react";

function Avatar(props) {
  const { img, name } = props;

  const PerspectiveCard = ({ imageUrl, deg }) => {
    const [{ rotX, rotY, shineX, shineY }, setPositions] = useState({
      rotX: 0,
      rotY: 0,
      shineX: 0.5,
      shineY: 0.5,
    });
    const cardRef = useRef();
    const imageRef = useRef();

    function handleMouseMove(evt) {
      const { clientX, clientY } = evt;
      const { top, left, width, height } =
        cardRef.current.getBoundingClientRect();
      const x = (clientX - left) / width;
      const y = (clientY - top) / height;
      setPositions({
        rotX: x * 2 - 1,
        rotY: y * 2 - 1,
        shineX: 100 - x * 90,
        shineY: 100 - y * 90,
      });
    }

    function handleMouseLeave() {
      setPositions({ rotX: 0, rotY: 0, shineX: 0.5, shineY: 0.5 });
    }

    useEffect(() => {
      cardRef.current.addEventListener("mousemove", handleMouseMove);
      cardRef.current.addEventListener("mouseleave", handleMouseLeave);
      // return () => {
      //   cardRef.current.removeEventListener("mousemove", handleMouseMove);
      //   cardRef.current.removeEventListener("mouseleave", handleMouseLeave);
      // };
    }, []);

    const imageStyle = {
      transform: `rotate3d(${rotX}, ${rotY}, 0, ${deg}deg)`,
    };

    const shineStyle = {
      background: `radial-gradient(at ${shineX}% ${shineY}%, rgba(255, 255, 255, 0.3), transparent)`,
    };

    return (
      <div ref={cardRef} className="card">
        <div style={imageStyle} ref={imageRef} className="card__container">
          <img className="card__image" src={imageUrl} />
          <div className="card__shine" style={shineStyle} />
        </div>
      </div>
    );
  };

  return (
    <AvatarCss className="cover atvImg">
      <PerspectiveCard imageUrl={img} deg={10} />
    </AvatarCss>
  );
}

const AvatarCss = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  transform-style: preserve-3d;
  will-change: transform;
  transition: var(--ease-2);
  transform: scale3d(1, 1, 1);

  &:hover {
    transform: scale3d(1.06, 1.06, 1.06);
  }

  .card {
    width: 100%;
    height: 100%;
    display: table-row;
    perspective: 1000px;

    &__shine {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 16px;
    }

    div,
    img {
      width: 100%;
      height: 100%;
    }
  }

  .card__image {
    border-radius: 16px;
    width: 100%;
    object-fit: cover;
  }
`;

export default Avatar;
