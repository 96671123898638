import styled from "styled-components";
import logo from "../assets/logo.png";
import pathText from "../assets/path-text.png";

function LogoComp() {
  return (
    <LogoCompCss className="logo-comp">
      <div className="logoWrap">
        <a href="https://figma.cool" target="_blank">
          <img src={logo} alt="logo" className="comp-logo" />
        </a>
        <span></span>
        <img src={pathText} alt="pathText" className="textCircle" />
      </div>
    </LogoCompCss>
  );
}

const flexCenter = `
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LogoCompCss = styled.div`
  /* position: fixed; */
  ${flexCenter}
  padding: 118px;
  border-radius: 100%;
  width: fit-content;
  max-width: 448px;
  max-height: 448px;
  background: var(--cr-bg-2);
  /* top: 50%;
  left: 50%;
  transform-origin: center;
  right: 50%;
  transform: translate(-50%, -50%); */

  .logoWrap,
  .textCircle {
    position: absolute;
  }

  .textCircle {
    width: 425px;
    height: 425px;
    will-change: rotate;
  }

  .logoWrap {
    position: relative;
    width: 340px;
    height: 340px;
    border-radius: 100%;
    ${flexCenter}

    .comp-logo {
      width: 266px;
      height: 266px;
      transition: var(--ease-2);
    }

    & > a {
      z-index: 1;
      ${flexCenter}
      width: 337px;
      height: 337px;
      background: #292929;
      border-radius: 100%;
    }

    span {
      filter: blur(20px);
      position: absolute;
      width: calc(340px + 3px);
      height: calc(340px + 3px);
      border-radius: 100%;
      transition: var(--ease-1);
      background: linear-gradient(
        36.86deg,
        #605cf7 28.56%,
        #f15c8d 54.61%,
        #eba800 86.92%,
        #01b5ff 100%
      );
    }
  }
`;

export default LogoComp;
