import styled from "styled-components";
import NeonLove from "../components/NeonLove";
import Btn from "../components/MainButton";
import avatar_1 from "../assets/avatar-1.png";
import avatar_2 from "../assets/avatar-2.png";
import avatar_3 from "../assets/avatar-3.png";
import logo from "../assets/dock-logo.png";

const AddSvg = () => {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="slogan-motion-obj slogan-motion-add"
    >
      <path
        d="M26.7412 0.0693503C26.9107 0.0239181 27.0893 0.0239181 27.2588 0.0693503L40.2412 3.54796C40.4107 3.5934 40.5653 3.68266 40.6895 3.80678L50.1932 13.3105C50.3173 13.4347 50.4066 13.5893 50.452 13.7588L53.9306 26.7412C53.9761 26.9107 53.9761 27.0893 53.9306 27.2588L50.452 40.2412C50.4066 40.4107 50.3173 40.5653 50.1932 40.6895L40.6895 50.1932C40.5653 50.3173 40.4107 50.4066 40.2412 50.452L27.2588 53.9306C27.0893 53.9761 26.9107 53.9761 26.7412 53.9306L13.7588 50.452C13.5893 50.4066 13.4347 50.3173 13.3105 50.1932L3.80678 40.6895C3.68266 40.5653 3.5934 40.4107 3.54796 40.2412L0.0693503 27.2588C0.0239181 27.0893 0.0239181 26.9107 0.0693503 26.7412L3.54796 13.7588C3.5934 13.5893 3.68266 13.4347 3.80678 13.3105L13.3105 3.80678C13.4347 3.68266 13.5893 3.5934 13.7588 3.54796L26.7412 0.0693503Z"
        fill="#5A9CFF"
      />
      <path
        fillRule="evenodd1"
        clipRule="evenodd1"
        d="M24.1074 29.8922L24.1074 43.3922H28.9289V29.8922H43.3931V25.0707H28.9288V10.6064L24.1074 10.6064L24.1074 25.0707H10.6074V29.8922H24.1074Z"
        fill="black"
      />
    </svg>
  );
};

function PrimaryCard(props) {
  const { btnClick } = props;
  return (
    <PrimaryCardCss id="primary-card" className="top-card">
      <div className="card-cover">
        <NeonLove />
      </div>
      <ul className="card-content">
        <li>
          <p>Figma.Cool</p>
          <div className="first-line-logo slogan-motion-obj slogan-motion-logo">
            <img src={logo} alt="logo" className="PrimaryCard-logo" />
          </div>
          <p>赞助计划</p>
        </li>
        <li>
          <AddSvg />
          <p>一起持续创造</p>
          <p className="asterisk dot">*</p>
          <p>2022</p>
        </li>
        <li>
          <p>支持我们</p>
          <div className="primary-card-avatars slogan-motion-avatar">
            <div className="slogan-motion-obj">
              <img src={avatar_1} alt="avatar" />
            </div>
            <div className="slogan-motion-obj">
              <img src={avatar_2} alt="avatar" />
            </div>
            <div className="slogan-motion-obj">
              <img src={avatar_3} alt="avatar" />
            </div>
          </div>
        </li>
        <Btn text="赞助我们" className="hero-btn" btnClick={btnClick} />
      </ul>
    </PrimaryCardCss>
  );
}

const PrimaryCardCss = styled.section`
  /* max-width: 1000px; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 700px;
  border-radius: 32px;
  overflow: hidden;
  margin-bottom: 20px;
  background: var(--cr-bg-2);

  .PrimaryCard-logo {
    width: 32px;
    height: 32px;
  }

  .card-cover {
    height: 100%;

    canvas {
      height: 100%;
    }
  }

  .card-content {
    z-index: 1;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
      width: 192px;
      margin-top: 24px;
    }

    .split-parent {
      overflow: hidden;
    }

    .primary-card-avatars {
      width: 122px;
      height: 54px;
      position: relative;
      display: flex;
      margin-left: 6px;

      div {
        position: absolute;
        width: 54px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;

        &:nth-of-type(1) {
          background: var(--color-iceBlue-1);
        }

        &:nth-of-type(2) {
          z-index: 1;
          background: var(--color-yellow-1);
          left: 34px;
        }

        &:nth-of-type(3) {
          left: 68px;
          z-index: 2;
          background: var(--color-red-1);
        }

        img {
          width: 32px;
          height: 32px;
        }
      }
    }

    li {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 54px;
      color: var(--cr-font-1);
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      &:nth-of-type(1) {
        .first-line-logo {
          width: 54px;
          height: 54px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          background: var(--color-red-1);
          margin: 0 6px;

          img {
            position: absolute;
          }
        }
      }
      &:nth-of-type(2) {
        svg {
          margin-right: 6px;
        }

        .asterisk {
          margin: 0 6px;
          height: 32px;
        }
      }
    }
  }
`;

export default PrimaryCard;
