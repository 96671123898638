import styled from "styled-components";
import footerLogo from "../assets/logo.png";
import ic_figma from "../assets/footer_figma.svg";
import ic_wechat from "../assets/footer_wechat.svg";
import ic_github from "../assets/footer_github.svg";
import ic_twitter from "../assets/footer_twitter.svg";
import ic_zhihu from "../assets/footer_zhihu.svg";
import wechat_qr from "../assets/wechat-qr.png";

function GlobalFooter() {
  return (
    <GlobalFooterCss>
      <img src={footerLogo} alt="footerLogo" className='footer-logo' />
      <div className="social-wrap">
        <a href="https://www.figma.com/@figmacool" className="link-figma">
          <img src={ic_figma} alt="figma" />
        </a>
        <a href="" className="link-wechat">
          <img src={ic_wechat} alt="wechat" />
        </a>
        <a href="https://github.com/Figma-Cool" className="link-github">
          <img src={ic_github} alt="github" />
        </a>
        <a
          href="https://www.zhihu.com/people/figma-cool"
          className="link-zhihu"
        >
          <img src={ic_zhihu} alt="zhihu" />
        </a>
        <a href="https://twitter.com/FigmaCool" className="link-twitter">
          <img src={ic_twitter} alt="twitter" />
        </a>
      </div>
      <p>Copyright © 2022 Figma.Cool</p>
    </GlobalFooterCss>
  );
}

const GlobalFooterCss = styled.footer`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 140px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;

  .footer-logo {
    width: 266px;
    height: 266px;
  }
  .social-wrap {
    margin: 32px 0 16px 0;
    display: flex;
  }

  p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 1);
    opacity: 0.3;
  }

  .link-wechat {
    position: relative;
    &:hover {
      &:after {
        opacity: 1;
        visibility: visible;
        transform: perspective(500px) rotateX(0);
      }
    }
    &:after {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      left: -48px;
      top: -132px;
      content: "";
      display: block;
      padding: 8px;
      width: 100px;
      height: 100px;
      background: url(${wechat_qr}) no-repeat 50%;
      background-size: cover;
      background-size: 94%;
      border-radius: 4px;
      transition: all 0.2s ease-out;
      transform-origin: bottom;
      transform: perspective(500px) rotateX(10deg) scale(0.96);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.6);
      filter: saturate(0) brightness(1.2);
    }
  }

  a:not(:last-child) {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    opacity: 0.85;
    transition: var(--ease-1);

    img {
      width: 100%;
      height: 100%;
    }

    &:hover {
      opacity: 1;
    }
  }
`;

export default GlobalFooter;
