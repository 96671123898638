import styled from "styled-components";
import { useEffect, useRef } from "react";
import gsap from "gsap";
// import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

// gsap.registerPlugin(MorphSVGPlugin);

function MainButton(props) {
  const { text, size, className, btnClick } = props;
  const MainButtonRef = useRef(null);

  const loveTl = gsap.timeline({
    defaults: {
      duration: 0.3,
      ease: "cubic-bezier(0.16, 1, 0.3, 1)",
      fill: "#f15c8d",
    },
  });

  // const handleMouseMove = (e) => {
  //   let t = MainButtonRef.current.getBoundingClientRect();
  //   let n = Math.min(((e.pageX - t.left) / t.width) * 100, 100),
  //     i = ((e.pageY - t.top) / t.height) * 0;
  //   setTimeout(() => {
  //     MainButtonRef.current.style.background =
  //       "radial-gradient(120px at " +
  //       n +
  //       "% " +
  //       i +
  //       "%, #3f3f3f, rgb(39, 39, 39), rgba(0, 0, 0, 1))";
  //   }, 100);
  // };

  // const handleMouseLeave = () => {
  //   setTimeout(() => {
  //     MainButtonRef.current.style.background = "rgba(0, 0, 0, 1)";
  //   }, 100);
  // };

  return (
    <MainButtonCss
      className={className}
      // onMouseMove={handleMouseMove}
      // onMouseLeave={handleMouseLeave}
      onClick={() => {
        btnClick(true);
      }}
    >
      <div className="btn-bg-black" ref={MainButtonRef}></div>
      <div className="button-content">
        <div> {text}</div>
        <svg
          class="octicon octicon-chevrow"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="octicon-chevrow-right"
            fill="#b3b3b3"
            d="M7.28033 3.21967C6.98744 2.92678 6.51256 2.92678 6.21967 3.21967C5.92678 3.51256 5.92678 3.98744 6.21967 4.28033L7.28033 3.21967ZM11 8L11.5303 8.53033C11.8232 8.23744 11.8232 7.76256 11.5303 7.46967L11 8ZM6.21967 11.7197C5.92678 12.0126 5.92678 12.4874 6.21967 12.7803C6.51256 13.0732 6.98744 13.0732 7.28033 12.7803L6.21967 11.7197ZM6.21967 4.28033L10.4697 8.53033L11.5303 7.46967L7.28033 3.21967L6.21967 4.28033ZM10.4697 7.46967L6.21967 11.7197L7.28033 12.7803L11.5303 8.53033L10.4697 7.46967Z"
          ></path>
          <path
            class="arrow-line"
            stroke="#b3b3b3"
            d="M1.75 8H11"
            stroke-width="1.5"
            stroke-linecap="round"
          ></path>
        </svg>
      </div>
      <span></span>
    </MainButtonCss>
  );
}

const MainButtonCss = styled.a`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--cr-bg-4);
  border-radius: 8px;
  padding: 16px 32px;
  cursor: pointer;
  transition: var(--ease-3);
  transform: translateY(0);

  .button-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button-content div {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    color: #b3b3b3;
    background: linear-gradient(to right, #b3b3b3, #fff 15%, #b3b3b3 29.41%);
    font-size: 18px;
    line-height: 19px;
    -webkit-background-clip: text;
    background-position: 65px;
    -webkit-text-size-adjust: none;
    text-decoration: none;
    white-space: nowrap;
  }

  &:hover {
    svg {
      transform: translateX(4px);

      .arrow-line {
        stroke-dashoffset: 20;
      }
    }
    .button-content div {
      -webkit-text-fill-color: transparent;
      animation: shine 1.5s linear infinite;
      animation-fill-mode: forwards;

      @keyframes shine {
        0% {
          background-position: 0;
        }

        100% {
          background-position: 65px;
        }
      }
    }
    transform: translateY(-4px) !important;

    span {
      box-shadow: 0 0 4px 4px
        linear-gradient(
          0deg,
          #605cf7 28.56%,
          #f15c8d 54.61%,
          #eba800 86.92%,
          #01b5ff 100%
        );

      &:after {
        filter: blur(14px);
        opacity: 0.6;
      }
    }
  }

  .button-content {
    z-index: 1;
  }

  .btn-bg-black {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: var(--cr-bg-4);
    border-radius: 8px;
  }

  span {
    z-index: -1;
    position: absolute;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: 8px;
    transition: var(--ease-1);
    background: linear-gradient(
      36.86deg,
      #605cf7 28.56%,
      #f15c8d 54.61%,
      #eba800 86.92%,
      #01b5ff 100%
    );

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      transition: var(--ease-3);
      background: linear-gradient(
        36.86deg,
        #605cf7 28.56%,
        #f15c8d 54.61%,
        #eba800 86.92%,
        #01b5ff 100%
      );
      filter: blur(0);
      opacity: 0;
    }
  }
  svg {
    margin-left: 6px;
    width: 18px;
    height: 18px;
    position: relative;
    margin: -4px 0;
    transition: transform 0.2s;
    transform: translateX(0);
    vertical-align: middle;
    opacity: 0.9;

    .arrow-line {
      stroke-dasharray: 10;
      stroke-dashoffset: 10;
      transition: stroke-dashoffset 0.2s;
    }
  }
`;

export default MainButton;
