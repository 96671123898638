import reset from "react-style-reset/string";
import { createGlobalStyle } from "styled-components";
import cursor from "../assets/cursor.svg";

const GlobalStyles = createGlobalStyle`
  ${reset};

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  .safari-section {
    transform: translateZ(0);
    /* transform: rotate(0deg) */
  }

  html, body {
    scroll-behavior: smooth;
  }
  
  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: var(--cr-bg-1);
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px;
    width: 100vw;
    display: flex;
    justify-content: center;
    cursor: url(${cursor}) 6 2, default;
    margin-bottom: 520px;
  }

  body::-webkit-scrollbar {
  display: none;
}

  :root {
      --cr-white: #fff;
      --cr-black: #000;
      --cr-bg-1: #333333;
      --cr-bg-2: #181818;
      --cr-bg-3: rgba(23, 23, 23, 0.06);
      --cr-bg-4: #000;
      --cr-line-1: rgba(255, 255, 255, 0.12);
      --cr-line-2: rgba(255, 255, 255, 0.4);
      --ease-1: all 0.25s ease;
      --ease-2: all 0.55s cubic-bezier(0.34, 1.56, 0.64, 1);
      --ease-3: all 0.45s ease;
      --ease-4: all 0.3s ease-out;
      --color-red-1: #f65c8a;
      --color-purple-1: #5f5cf6;
      --color-iceBlue-1: #00b5ff;
      --color-yellow-1: #eba800;
      --cr-font-1: rgba(255, 255, 255, 0.9);
      --cr-font-2: rgba(255, 255, 255, 0.8);
      --cr-font-3: rgba(255, 255, 255, 0.7);
  }

  .p-text {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #E8E8E8;
    opacity: 0.8;
  }

  .hero-btn {
    opacity: 0;
  }

  .popup-show {
    opacity: 1 !important;
    visibility: visible !important;

    .popup-content-top, .popup-content {
      transform: translate3d(0,0,0) !important;
      opacity: 1 !important;
    }
  }
`;

export default GlobalStyles;
