import { useRef } from "react";
import styled from "styled-components";
import arrow from "../assets/projects/link-arrow.svg";

function ProjectItem(props) {
  const cardRef = useRef(null);

  const handleMouseLeave = () => {
    setTimeout(() => {
      cardRef.current.style.background = "rgba(255, 255, 255, 0.7)";
    }, 100);
  };

  const handleMouseMove = (e) => {
    let t = cardRef.current.getBoundingClientRect();
    let n = Math.min(((e.pageX - t.left) / t.width) * 100, 100),
      i = ((e.pageY - t.top) / t.height) * 10;
    setTimeout(() => {
      cardRef.current.style.background =
        "radial-gradient(440px at " +
        n +
        "% " +
        i +
        "%, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7))";
    }, 100);
  };

  const { title, des, icon, url } = props;
  return (
    <ProjectItemCss
      onMouseMove={(e) => handleMouseMove(e)}
      ref={cardRef}
      onMouseLeave={handleMouseLeave}
      href={url}
      target="_blank"
      rel="noreferrer noopener"
    >
      <div className="project-item-top">
        <h3>{title}</h3>
        <p>{des}</p>
      </div>
      <div className="project-item-bottom">
        <img src={icon} alt="icon" className="item-icon" />
        <div className="link-arrow">
          <img src={arrow} alt="arrow" />
        </div>
      </div>
    </ProjectItemCss>
  );
}

const ProjectItemCss = styled.a`
  cursor: grab;
  width: 100%;
  /* min-width: 200px; */
  /* max-width: 320px; */
  height: 265px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px;
  background: rgba(255, 255, 255, 0.7);
  /* backdrop-filter: blur(40px); */
  border-radius: 14px;

  &:hover {
    .link-arrow {
      &:after {
        opacity: 1 !important;
        transform: scale(1) !important;
      }
      img {
        transform: translate(0, 0) !important;
        opacity: 1 !important;
      }
    }
  }

  .project-item-bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .link-arrow {
      position: relative;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:after {
        opacity: 0;
        z-index: -1;
        position: absolute;
        content: "";
        width: 24px;
        height: 24px;
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        transform: scale(0.6);
        transition: var(--ease-1);
        transform-origin: left bottom;
        transition-delay: 0.04s;
      }

      img {
        opacity: 0;
        width: 12px;
        height: 12px;
        transform: translate(-4px, 4px);
        transition: var(--ease-1);
      }
    }
  }

  h3 {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px !important;
    line-height: 22px;
    color: var(--cr-black);
    margin-bottom: 8px;
  }

  p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: var(--cr-black);
  }

  .item-icon {
    width: 56px;
    height: 56px;
  }
`;

export default ProjectItem;
