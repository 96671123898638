import styled from "styled-components";
import { useState, useEffect } from "react";

function TabSwitcher(props) {
  const { changePlus } = props;
  const [tabActive, setTabActive] = useState(true);

  // useEffect(() => {
  //   console.log(changePlus);
  // }, []);

  return (
    <TabSwitcherCss className="tabs">
      <div className="tab-label">
        <div
          className={
            tabActive
              ? "tab-left tab-item tab-active-text"
              : "tab-left tab-item"
          }
          onClick={() => {
            setTabActive(!tabActive);
            changePlus(true);
          }}
        >
          Plus 赞助者
        </div>
        <div
          className={
            !tabActive
              ? "tab-right tab-item tab-active-text"
              : "tab-right tab-item"
          }
          onClick={() => {
            setTabActive(!tabActive);
            changePlus(false);
          }}
        >
          赞助者
        </div>
      </div>
      <span
        className={tabActive ? "active-bar tab-active" : "active-bar"}
      ></span>
    </TabSwitcherCss>
  );
}

const TabSwitcherCss = styled.div`
  position: relative;
  padding: 2px;
  border-radius: 8px;
  width: 320px;
  height: 32px;
  background: var(--cr-bg-1);
  color: var(--cr-font-1);
  display: flex;
  justify-content: center;
  align-items: center;

  .tab-label {
    width: 100%;
    display: flex;
  }

  .tab-item {
    cursor: pointer;
    z-index: 1;
    width: 50%;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-radius: 7px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.2px;
    transition: var(--ease-1);

    &:hover {
      opacity: 0.8;
    }
  }

  .tab-active {
    transform: translateX(-100%);
  }

  .tab-active-text {
    color: var(--cr-bg-1);
  }

  .active-bar {
    position: absolute;
    width: calc(50% - 2px);
    height: 28px;
    background: var(--cr-white);
    opacity: 0.8;
    left: 50%;
    transition: var(--ease-1);
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  }
`;

export default TabSwitcher;
