import { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import MainButton from "../components/MainButton";
import gsap from "gsap";
import ClipboardJS from "clipboard";
import logo from "../assets/dock-logo.png";
import iconCopy from "../assets/icon-copy.png";

function Dock(props) {
  const dock = useRef(null);
  const [copy, setCopy] = useState(false);
  const { btnClick } = props;

  useEffect(() => {
    const clipboard = new ClipboardJS(".copy-btn");

    clipboard.on("success", () => {
      setCopy(true);
      setTimeout(() => {
        setCopy(false);
      }, 3000);
    });
  }, []);

  //   function magnetize(el, e) {
  //     var mX = e.pageX,
  //       mY = e.pageY;
  //     const item = el;

  //     const customDist = item.getAttribute("data-dist") * 20 || 120;
  //     const centerX = item.offsetLeft + item.offsetWidth / 2;
  //     const centerY = item.offsetTop + item.offsetHeight / 2;

  //     var deltaX = Math.floor(centerX - mX) * -0.45;
  //     var deltaY = Math.floor(centerY - mY) * -0.45;

  //     var distance = calculateDistance(el, mX, mY);

  //     if (distance < customDist) {
  //       gsap.to(item, { duration: 0.5, y: deltaY, x: deltaX, scale: 1.1 });
  //     } else {
  //       gsap.to(item, { duration: 0.6, y: 0, x: 0, scale: 1 });
  //     }
  //   }

  //   function calculateDistance(elem, mouseX, mouseY) {
  //     return Math.floor(
  //       Math.sqrt(
  //         Math.pow(mouseX - (elem.offsetLeft + elem.offsetWidth / 2), 2) +
  //           Math.pow(mouseY - (elem.offsetTop + elem.offsetHeight / 2), 2)
  //       )
  //     );
  //   }

  //   useEffect(() => {
  //     document.addEventListener("mousemove", (e) => {
  //       magnetize(dock.current, e);
  //     });
  //   }, []);

  const handleMouseMove = (e) => {
    let t = dock.current.getBoundingClientRect();
    let n = Math.min(((e.pageX - t.left) / t.width) * 100, 100),
      i = ((e.pageY - t.top) / t.height) * 0;
    setTimeout(() => {
      dock.current.style.background =
        "radial-gradient(160px at " +
        n +
        "% " +
        i +
        "%, rgba(53, 53, 53, 0.5), rgba(53, 53, 53, 0.7), rgba(28, 28, 28, 0.7))";
    }, 100);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      dock.current.style.background = "rgba(28, 28, 28, 0.7)";
    }, 100);
  };

  return (
    <DockCss
      ref={dock}
      data-dist="7"
      onMouseMove={(e) => handleMouseMove(e)}
      onMouseLeave={handleMouseLeave}
      id="dock"
    >
      <img src={logo} alt="logo" className="dock-logo" />
      <a className="dock-link-bg" href="#article-card">
        关于赞助
      </a>
      <a className="dock-link-bg" href="#project-card">
        Figma.Cool 的项目
      </a>
      <a className="dock-link-bg" href="#returns-card">
        我们的回馈
      </a>
      <button
        className="dock-link-bg copy-btn"
        data-clipboard-text="https://love.figma.cool"
      >
        <span className={copy ? "copy-tip" : ""}>🔗 已复制网址</span>
        <img src={iconCopy} alt="iconCopy" />
      </button>
      <MainButton
        text="赞助我们"
        className="dock-main-btn"
        btnClick={btnClick}
      />
    </DockCss>
  );
}

const DockCss = styled.div`
  display: flex;
  align-items: center;
  z-index: 99999;
  position: fixed;
  bottom: 20px;
  padding: 14px;
  color: var(--cr-font-2);
  background: rgba(28, 28, 28, 0.7);
  backdrop-filter: blur(30px);
  border-radius: 16px;
  box-shadow: inset 0 1px 1px hsl(0deg 0% 100% / 14%);

  .dock-main-btn {
    padding: 12px 16px;

    &:hover {
      transform: translateY(0) !important;
    }

    .button-content div {
      font-size: 15px;
      line-height: 15px;

      svg {
        width: unset;
        height: unset;
      }
    }
  }

  .dock-logo {
    margin-right: 20px;
    width: 32px;
    height: 32px;
  }

  .copy-tip {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }

  .copy-btn {
    position: relative;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;

    img {
      width: 32px;
      height: 32px;
    }

    span {
      opacity: 0;
      transition: var(--ease-1);
      transform: translateY(6px);
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 13px;
      width: 110px;
      top: -60px;
      height: 32px;
      box-shadow: 0 2px 3px rgb(0 0 0 / 58%),
        inset 0 1px 1px hsl(0deg 0% 100% / 14%);
      left: -120%;
      background: var(--cr-bg-2);
      border-radius: 4px;
    }

    &:after {
      padding: 0 !important;
    }
  }

  .dock-link-bg:last-child {
    font-size: 15px;
    line-height: 15px;
    padding: 12px 16px;
  }

  .dock-link-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    color: var(--cr-font-3);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 15px;
    margin-right: 20px;

    &:hover {
      &:after {
        opacity: 0.1;
        transform: scale(1);
      }
    }

    &:after {
      content: "";
      z-index: -1;
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 10px 8px;
      background: var(--cr-white);
      opacity: 0;
      border-radius: 6px;
      transition: var(--ease-1);
      transform: scale(0.94);
    }
  }
`;

export default Dock;
