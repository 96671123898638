import { useEffect, useState } from "react";
import gsap from "gsap";
import styled from "styled-components";
import Title from "../components/Title";
import avatar_1 from "../assets/avatar-1.png";
import avatar_2 from "../assets/avatar-2.png";
import avatar_3 from "../assets/avatar-3.png";

// const talking = `你的赞助对我们来说意义重大，因为有大家的支持才能让我们专注于维护 FIgma.Cool 和构想新的想法，而不必再担心资金的压力。

// 我们在这半年里通过 [Figma.Cool](http://figma.Cool) 和大家建立了连接。最初我们单纯的想做 Figma 的汉化，刚开始的 Chrome 汉化插件是在今年 1 月初疫情还未爆发的时候上线，2 月初的时候从统计后台明显看到安装使用量的上升趋势，慢慢有网友找到我们给到反馈和想法。复工后我们开始做了资源站和插件，后来想到把大家聚集在一起就建了微信群。

// 为什么要做这件事？我们非常认同 Figma 所构想的愿景，用一种自由开放的协作方式和开源社区生态去重构现存环境，就像历史上每个时间跨度里的转折点那样，这是我们最为核心的推崇 Figma 的地方。其次是 Figma 在设计工具层面上的优势，这点大家都有体会。

// 如果你喜欢我们的工作并对 Figma.Cool 感兴趣，请赞助我们。

// 谢谢你。
// Figma.Cool Team`;

const Avatars = [
  {
    img: avatar_1,
  },
  {
    img: avatar_1,
  },
  {
    img: avatar_1,
  },
  {
    img: avatar_2,
  },
  {
    img: avatar_2,
  },
  {
    img: avatar_2,
  },
  {
    img: avatar_3,
  },
  {
    img: avatar_3,
  },
  {
    img: avatar_1,
  },
  {
    img: avatar_3,
  },
  {
    img: avatar_3,
  },
  {
    img: avatar_2,
  },
];

function ArticleCard(props) {
  const { motion } = props;
  const [motionCommand, setMotionCommand] = useState(motion);

  // function generate() {
  //   const tl = gsap.timeline();
  //   const circle = document.querySelectorAll(".card-cover li");

  //   function blurRandom() {
  //     const nums = [0, 0, 0, 0, 16, 10];
  //     function getRandomInt(max) {
  //       return Math.floor(Math.random() * max);
  //     }
  //     return "blur(" + nums[getRandomInt(7)] + "px)";
  //   }

  //   tl.to(circle, {
  //     x: 0,
  //     y: 600,
  //     ease: "elastic.out(1, 0.5)",
  //     duration: 1,
  //     filter: () => blurRandom(),
  //   })
  //     .to(circle, {
  //       x: "random(-15, 4, true)",
  //       y: 0,
  //       ease: "elastic.out(1, 0.5)",
  //       duration: 3,
  //       delay: "random(0, 0.2, true)",
  //     })
  //     .to(circle, {
  //       x: "random(-15, 20, true)",
  //       y: "random(-15, 20, true)",
  //       ease: "ease",
  //       scale: () => {
  //         const nums = [0.9, 1];
  //         function getRandomInt(max) {
  //           return Math.floor(Math.random() * max);
  //         }
  //         return nums[getRandomInt(2)];
  //       },
  //       duration: 4,
  //       filter: () => blurRandom(),
  //       repeat: -1,
  //       repeatRefresh: true,
  //       // repeatDelay: 0.6,
  //     });
  // }

  // useEffect(() => {
  //   setMotionCommand(motion);
  //   if (motionCommand === "start") {
  //     generate();
  //   }
  // }, []);

  return (
    <ArticleCardCss id="article-card" className="top-card">
      <div className="card-cover">
        {Avatars.map((item, i) => {
          return (
            <li className="article-card-avatar-wrap" key={i}>
              <img
                src={item.img}
                alt="avatar"
                className="article-card-avatar"
              />
            </li>
          );
        })}
      </div>
      <div className="card-content">
        <Title title="感谢来自你的赞助！" subTitle="让我们成为可能" />
        <article className="p-text">
          你的赞助对我们来说意义重大，因为有大家的支持才能让我们专注于维护
          Figma.Cool
          和构想新的想法，而不必再担心资金的压力。很高兴能从你这里知道我们工作的价值，这有助于激励我们。如果
          Figma.Cool
          所做的这些事情有助于让你的工作变得更轻松或更好，并且你非常愿意的话，那么赞助是向我们表达这一点的途径。
          <br />
          <br />
          我们在过去的一年多时间里通过 Figma.Cool 和大家建立了连接。最初我们只想到了做
          Figma 的汉化，刚开始的 Chrome 汉化插件是在 2020 年 1
          月初疫情还未爆发的时候上线，2
          月初的时候从统计后台明显看到安装使用量的上升趋势，慢慢有朋友找到我们给到非常棒的反馈和想法。等复工之后我们开始做了资源站和各类插件，并且一直花费了大量的个人时间来迭代更新。
          <br />
          <br />
          为什么要做这件事？我们非常认同 Figma
          所构想的愿景，用一种自由开放的协作方式和开源社区生态去重构现存环境，就像历史上每个时间跨度里的转折点那样，这是我们最为核心地推崇
          Figma 的地方。其次是 Figma 在设计工具层面上的优势，这点大家都有体会。
          <br />
          <br />
          在武汉的时候，Covien
          和我每天都走在相同的小路上碰撞想法，我们想做更多的产品和有趣的事情，迫不及待。Figma.Cool
          是我们做的第一件事，现在回望，这件事带给了我们太多的收获，最重要的收获是和大家建立了连接，认识到了很多有想法有热情的朋友，互相发光照亮对方。这在我们眼中是最珍贵的存在，也是所有事情的源动力。
          <br />
          <br />
          如果你喜欢我们的工作并对 Figma.Cool 感兴趣，请赞助我们。
          谢谢你。当然也很期待你发邮件至 figmacool@gmail.com 来和我们聊聊。
          <br />
          <br />
          Figma.Cool Team
        </article>
      </div>
    </ArticleCardCss>
  );
}

const ArticleCardCss = styled.section`
  /* max-width: 1000px; */
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-radius: 32px;
  background: var(--cr-bg-2);
  overflow: hidden;

  .card-cover {
    position: relative;
    width: 100%;
    height: 600px;

    .article-card-avatar-wrap {
      &:nth-of-type(1) {
        background: var(--color-red-1);
        position: absolute;
        top: 20%;
        left: 32%;
        box-shadow: 0 0 12px var(--color-red-1);
      }
      &:nth-of-type(2) {
        width: 90px;
        height: 90px;
        background: var(--color-iceBlue-1);
        position: absolute;
        top: 28%;
        left: 60%;
        box-shadow: 0 0 12px var(--color-iceBlue-1);
      }
      &:nth-of-type(3) {
        width: 90px;
        height: 90px;
        background: var(--color-red-1);
        position: absolute;
        top: 44%;
        left: 70%;
        box-shadow: 0 0 12px var(--color-red-1);
      }
      &:nth-of-type(4) {
        background: var(--color-yellow-1);
        position: absolute;
        top: -10%;
        left: 62%;
        box-shadow: 0 0 12px var(--color-yellow-1);
      }
      &:nth-of-type(5) {
        width: 75px;
        height: 75px;
        background: var(--color-purple-1);
        position: absolute;
        top: 16%;
        left: 50%;
        box-shadow: 0 0 12px var(--color-purple-1);
      }
      &:nth-of-type(6) {
        width: 75px;
        height: 75px;
        background: var(--color-iceBlue-1);
        position: absolute;
        top: 10%;
        left: 20%;
        box-shadow: 0 0 12px var(--color-iceBlue-1);
      }
      &:nth-of-type(7) {
        width: 75px;
        height: 75px;
        background: var(--color-red-1);
        position: absolute;
        top: -4%;
        left: 30%;
        box-shadow: 0 0 12px var(--color-red-1);
      }
      &:nth-of-type(8) {
        background: var(--color-purple-1);
        position: absolute;
        top: 16%;
        left: 78%;
        box-shadow: 0 0 12px var(--color-purple-1);
      }
      &:nth-of-type(9) {
        width: 90px;
        height: 90px;
        background: var(--color-red-1);
        position: absolute;
        top: 40%;
        left: 10%;
        box-shadow: 0 0 12px var(--color-red-1);
      }
      &:nth-of-type(10) {
        width: 90px;
        height: 90px;
        background: var(--color-yellow-1);
        position: absolute;
        top: 40%;
        left: 42%;
        box-shadow: 0 0 12px var(--color-yellow-1);
      }
      &:nth-of-type(11) {
        width: 90px;
        height: 90px;
        background: var(--color-yellow-1);
        position: absolute;
        top: 14%;
        left: 8%;
        box-shadow: 0 0 12px var(--color-yellow-1);
      }
      &:nth-of-type(12) {
        width: 75px;
        height: 75px;
        background: var(--color-iceBlue-1);
        position: absolute;
        top: 8%;
        left: 90%;
        box-shadow: 0 0 12px var(--color-iceBlue-1);
      }
    }

    li {
      width: 130px;
      height: 130px;
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      /* background: var(--color-red-1); */
      transition: var(--ease-2);

      img {
        width: 55%;
        height: 55%;
      }
    }
  }

  .card-content {
    width: 100%;
    padding: 48px;
    background: var(--cr-bg-3);
    border-top: 1px solid var(--cr-line-1);
    backdrop-filter: blur(120px);
    margin-top: -300px;

    .card-title {
      margin-bottom: 12px;
    }

    article {
      /* word-break: keep-all; */
    }
  }
`;

export default ArticleCard;
